import * as React from "react"
import Layout from "../components/layout"
import ServiceTop from "../components/service-page/service-top"
import SkillsSlider from "../components/skills-slider/skills-slider"
import ServiceInfoCards from "../components/service-page/service-info-cards"
import { applikacioFejlesztes } from "../interfaces/service/services/applikacio-fejlesztes"
import MetaData from "../components/metadata"
import StructuredData from "../components/structured-data"
import PageBottom from "../components/page-bottom/page-bottom"

const ApplikacioFejlesztesPage = (props) => {
  return <Layout subHeader={false}>
    <MetaData title={'Applikáció fejlesztés'} url={props.location.href} description={"Tudtad, hogy a vásárlók több mint 50%-a telefonról nyitja meg a weboldalakat? Ne maradj le a feltörekvő applikáció piacról."}/>
    <StructuredData title={'Applikáció fejlesztés'} url={props.location.href} description={"Tudtad, hogy a vásárlók több mint 50%-a telefonról nyitja meg a weboldalakat? Ne maradj le a feltörekvő applikáció piacról."}/>
    <ServiceTop service={applikacioFejlesztes} />
    <SkillsSlider />
    <ServiceInfoCards infoCards={applikacioFejlesztes.cards} />
    <PageBottom/>
  </Layout>
}

export default ApplikacioFejlesztesPage
