import * as React from "react"
import { Container, makeStyles } from "@material-ui/core"
import ServiceInfoCard from "../cards/service-info-card"
import { ServiceInfoCardChildInterface, } from "../../interfaces/service-info-card-interface/service-info-card-interface"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: 400,
    paddingTop: 30,
    paddingBottom: 30,
    backgroundImage: `url("images/wave_bg_4.jpg")`
  },
  cards: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    maxWidth: 1200,
    [theme.breakpoints.down(900)]: {
      width: "85%"
    },
    [theme.breakpoints.down(650)]: {
      width: "90%"
    },
  }
}))

interface InfoCardsProps {
  infoCards: ServiceInfoCardChildInterface[]
}

const ServiceInfoCards = (props: InfoCardsProps) => {
  const classes = useStyles()

  return <div className={classes.root}>
    <Container className={classes.cards}>
      {props.infoCards.map((c, i) =>
        <ServiceInfoCard
          key={i}
          title={c.title}
          description={c.description}
          boldDescription={c.boldDescription}
          reverse={c.reverse}
          displayXo={false}
          bigImg={c.bigImg}
          picture={c.picture}
        />
      )}
    </Container>
  </div>
}

export default ServiceInfoCards
