import { Service } from "../service-interface"
import { applikacioFejlesztesInfoCards } from "../../service-info-card-interface/used_by/applikacio-fejlesztes-cards"

export const applikacioFejlesztes : Service = {
  title: 'Applikáció fejlesztés',
  description: "Tudtad, hogy a vásárlók több mint 50%-a telefonról nyitja meg a weboldalakat? Egy jó applikációval direkt módon ott lehetsz a vevőid telefonján. Ez által extra eléréshez és extra bevételhez juthatsz.",
  icon: "/images/phone.svg",
  topimg: "/images/app_fejlesztes.png",
  cards: applikacioFejlesztesInfoCards,
  listPoints: [
    "A vásárlók több mint 50%-a telefonról nyitja meg a weboldalakat",
  ]
}
