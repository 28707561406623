import * as React from "react"
import { makeStyles, Paper } from "@material-ui/core"
import ServiceTopInfo from "./service-top-info"
import ServiceTopImg from "./service-top-img"
import { Service } from "../../interfaces/service/service-interface"

const useStyles = makeStyles((theme) => ({
  divAll: {
    backgroundColor: "#ecf2f5",
    width: "100%",
    height: "auto",
    paddingTop: 80,
    paddingBottom: 20,
  },
  half: {
    width: "50%",
    [theme.breakpoints.down(1000)]: {
      width: "100%"
    }
  },
  content: {
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    [theme.breakpoints.down(1000)]: {
      flexWrap: "wrap-reverse"
    },
    maxWidth: 1200,
  }
}))

interface ServiceTopProps {
  service: Service
}

const ServiceTop = (props: ServiceTopProps) => {
  const classes = useStyles()

  return <div className={classes.divAll}>
    <div className={classes.content}>
      <div className={classes.half}>
        <ServiceTopInfo title={props.service.title}
                        description={props.service.description}
                        icon={props.service.icon}
                        listPoints={props.service.listPoints}
        />
      </div>
      <div className={classes.half}>
        <ServiceTopImg  title={props.service.title}
                        topimg={props.service.topimg} />
      </div>
    </div>
  </div>
}

export default ServiceTop
