import * as React from "react"
import { makeStyles, Paper } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 1200,
    margin: 'auto'
  },
  paperAll: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    padding: 15,
    [theme.breakpoints.down(1000)]: {
      flexWrap: "wrap-reverse"
    }
  },
  divTexts: {
    width: "60%",
    [theme.breakpoints.down(1000)]: {
      width: "95%"
    }
  },
  divTitle: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: "bold",
    padding: '5px 15px',
  },
  divBoldDescription: {
    fontSize: 16,
    lineHeight: "2em",
    textAlign: "inherit",
    fontWeight: "bold",
    color: "black",
    padding: 15,
  },
  divDescription: {
    fontSize: 16,
    lineHeight: "2em",
    textAlign: "inherit",
    color: "#898989",
    padding: 15,
  },
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "35%",
    height: "auto",
    [theme.breakpoints.down(1000)]: {
      width: "95%"
    }
  },
  img: {
    width: "100%",
    maxWidth: '420px',
    height: "auto",
    marginBottom: 0,
    borderRadius: 10,
    [theme.breakpoints.down(1000)]: {
      height: 200
    }
  },
  divXoImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingBottom: 8,
    paddingRight: 5
  },
  xoImg: {
    width: 70,
    height: 22,
    marginBottom: 0
  },
  grow: {
    flex: 1
  },
  reverse: {
    flexDirection: "row-reverse"
  },
  bigImg: {
    height: 200,
    width: 400
  }
}))

const ServiceInfoCard = ({ title, description, boldDescription, reverse, bigImg = false, displayXo = false, picture}) => {
  const classes = useStyles()

  return <div className={classes.root}>
    {displayXo && <div className={classes.divXoImg}>
      <img src="/images/xo_icons_2.png" className={classes.xoImg} alt={"xo"}/>
    </div>}
    <Paper className={`${classes.paperAll} ${reverse ? classes.reverse : ""}`} elevation={4}>
      <div className={classes.divTexts}>
        <h2 className={classes.divTitle}>
          {title}
        </h2>
        <div className={classes.divBoldDescription}>{boldDescription}</div>
        <div className={classes.divDescription}>{description}</div>
      </div>
      <div className={classes.grow} />
      <div className={classes.divImg}>
        <img src={picture} className={`${classes.img} ${bigImg ? classes.bigImg : ""}`} alt={"Card pic"}/>
      </div>
    </Paper>
  </div>
}

export default ServiceInfoCard
