import * as React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  divAll: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down(1000)]: {
      flexWrap: "wrap"
    },
    padding: 15,
    paddingTop: 25
  },
  divTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: 24,
    fontWeight: "bold",
    paddingTop: 25,
    paddingBottom: 25,
    marginTop: 0,
    marginBottom: 0
  },
  img: {
    width: 70,
    height: 22,
    marginBottom: 0
  },
  description: {
    fontSize: 14,
    lineHeight: "2em"
  },
  divImg: {
    marginRight: 15
  },
  ul: {
    color: '#00B790',
    "& p": {
      color: "rgba(90, 88, 88, 0.8)",
      fontSize: 14,
    }
  }
}))

const ServiceTopInfo = ({ title, description, icon, listPoints }) => {
  const classes = useStyles()

  return <div className={classes.divAll}>
    <img src="/images/xo_icons_2.png" className={classes.img} alt={"Xo"} />
    <h1 className={classes.divTitle}>
      <div className={classes.divImg}>
        <img src={icon} height={50} width={50} style={{ marginBottom: 0 }} alt={"icon"} />
      </div>
      {title}
    </h1>
    <div dangerouslySetInnerHTML={{ __html: description }} className={classes.description} />
    <ul className={classes.ul}>
      {
        listPoints.map(lp => {
          return <li><p>{lp}</p></li>
        })
      }
    </ul>
  </div>
}

export default ServiceTopInfo
