import * as React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  divAll: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(1000)]: {
      paddingTop: 20
    }
  },
  img: {
    width: 450,
    height: 300,
    marginBottom: 0
  }
}))

const ServiceTopImg = ({ topimg, title }) => {
  const classes = useStyles()

  return <div className={classes.divAll}>
    <img src={topimg} className={classes.img} alt={title} title={title}/>
  </div>
}

export default ServiceTopImg
