import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const app1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "Érje el üzleti eredményeit iOS-alkalmazással a bejáratott, műszakilag hozzáértő közönségnél, hogy nagyobb bevételre tegyen szert",
  description: "Építsen versenyelőnyt az intelligens iOS fejlesztői megoldásokkal. Érje el a mobil felhasználók piacát, amivel növelheti a vállalkozását",
  displayXo: true,
  reverse: false,
  title: "iOS fejlesztés",
  picture: "/images/services/app_card_1.jpg"
}

const app2 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "Az Android az összes mobil eszköz 74%-át teszi ki. Egy sikeres Android alkalmazás segíthet egy hatalmas piacra való belépésben",
  description: "Az egyedi technológiai megoldások és az üzleti célok ötvözésével elérheti a világ legnagyobb mobilalkalmazás piacát",
  displayXo: false,
  reverse: true,
  title: "Andoid alkalmazás",
  picture: "/images/services/app_card_2.jpg"
}

const app3 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "Egy sikeres mobilalkalmazás intelligensen ötvözi a felhasználókat, a vállalat vagy piac követelményeit és magát a terméket",
  description: "Ezeknek együtt kell működniük egy olyan alkalmazás létrehozásában, ami egyedülálló értéket biztosít",
  displayXo: true,
  reverse: false,
  title: "React Native",
  picture: "/images/services/app_card_3.jpg"
}

const app4 : ServiceInfoCardChildInterface = {
  bigImg: false,
  boldDescription: "A többplatformos mobilalkalmazás-fejlesztés egyszerűbbé vált, így jobb eredmények elérése érdekében intelligens módon kombinálhatja üzleti céljait technológiai megoldásokkal",
  description: "A Flutter a Google fejlesztői környezete, amit a multiplatformos alkalmazások készítésére hoztak létre, lehetővé téve a hatékony és gyors fejlesztést",
  displayXo: false,
  reverse: true,
  title: "Flutter fejlesztés",
  picture: "/images/services/berles_card_3.jpg"
}

export const applikacioFejlesztesInfoCards = [
  app1,
  app2,
  app3,
  app4
]